import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomePageComponent } from "./home-page/home-page.component";
import { LoginComponent } from "./login/login.component";


const routes: Routes = [
  { path: "", component: HomePageComponent },
  { path: "home-page", component: HomePageComponent },
  { path: "live", component: LoginComponent },
  { path: 'page', loadChildren: './pages/pages.module#PagesModule'}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
