import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

declare var bootbox: any;
declare var $: any;

@Component({
  selector: 'app-home-navbar',
  templateUrl: './home-navbar.component.html',
  styleUrls: ['./home-navbar.component.scss']
})
export class HomeNavbarComponent implements OnInit {
  toggleMenu: boolean;
  flag: boolean = true;
  message:string;
  lang: string;
  constructor(  private router: Router ) { }

  ngOnInit(): void {

    $( '.navbar-collapse .ss-menu' ).click( function() {
      $( ".navbar-collapse" ).collapse( 'hide' );
  } );
  
  this.lang = sessionStorage.getItem('language');
  if(this.lang=='ENGLISH'){
      this.flag = true;       
      }
  else{
      this.flag = false;  
  }

}


ontoggleMenu() {
  if ( this.toggleMenu === true ) {
      this.toggleMenu = false;
  } else {
      this.toggleMenu = true;
  }

}

languageChange(){
  if(this.lang=="ENGLISH"){
      sessionStorage.setItem( 'language',"TAMIL" );
      location.reload();
  }
  else{
      sessionStorage.setItem( 'language',"ENGLISH" );
      location.reload();
  }
}

logout() {

  this.router.navigate( [``] );
}
}