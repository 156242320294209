import { Component } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Samayapuram Mariamman Temple';

  loadingFlag: boolean;

  constructor(private router: Router) {

      this.router.events.subscribe((event) => {
          switch (true) {
              case event instanceof NavigationStart: {
                  this.loadingFlag = true;
                  break;
              }
              case event instanceof NavigationEnd:
              case event instanceof NavigationCancel:
              case event instanceof NavigationError: {
                  this.loadingFlag = false;
                  break;
              }
              default: {
                  break;
              }
          }
      });

  }

  onActivate() {
    window.scroll(0, 0);
}


}
