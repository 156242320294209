import { Component, OnInit } from '@angular/core';


declare var bootbox: any;
declare var $: any;

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  lang: string;
  constructor() { }

  ngOnInit(): void {

    this.lang = sessionStorage.getItem('language');
    if(this.lang=='ENGLISH'){
        this.lFlag = true;
        }
    else{
        this.lFlag = false;
    }


    let btn = $('#button');
    $(window).scroll(function() {
        if ($(window).scrollTop() > 300) {
            btn.addClass('show');
        } else {
            btn.removeClass('show');
        }
    });

    btn.on('click', function(e) {
        e.preventDefault();
        $('html, body').animate({ scrollTop: 0 }, '300');
    });

    $(document).ready(function(){
        $('[data-toggle="tooltip"]').tooltip();
      });
      ///// slider animation//
      // $(function() {
      //   $(".handler span").on("click", function() {
      //     $(this).addClass("choosen").siblings().removeClass("choosen");
      //     $(".slide")
      //       .eq($(this).index())
      //       .addClass("show")
      //       .siblings()
      //       .removeClass("show");
      //   });
      // });
      ////////////////////////
      ///// slider animation slide 2 ///
      // $(".project-detail").slick({
      //   slidesToShow: 1,
      //   arrows: false,
      //   asNavFor: '.project-strip',
      //   autoplay: true,
      //   autoplaySpeed: 3000
      // });

      // $(".project-strip").slick({
      //   slidesToShow: 5,
      //   slidesToScroll: 1,
      //   arrows: false,
      //   asNavFor: '.project-detail',
      //   dots: false,
      //   infinite: true,
      //   centerMode: true,
      //   focusOnSelect: true
      // });
      ///////////////////// animation 3 /////////
      // $('input').on('change', function() {
      //   $('body').toggleClass('blue');
      // });

      /////////////////////////////////////////// animative slide 4 ///////////////////////////////
//       var curpage = 1;
// var sliding = false;
// var click = true;
// var left = document.getElementById("left");
// var right = document.getElementById("right");
// var pagePrefix = "slide";
// var pageShift = 500;
// var transitionPrefix = "circle";
// var svg = true;

// function leftSlide() {
// 	if (click) {
// 		if (curpage == 1) curpage = 5;
// 		console.log("woek");
// 		sliding = true;
// 		curpage--;
// 		svg = true;
// 		click = false;
// 		for (let k = 1; k <= 4; k++) {
// 			var a1 = document.getElementById(pagePrefix + k);
// 			a1.className += " tran";
// 		}
// 		setTimeout(() => {
// 			move();
// 		}, 200);
// 		setTimeout(() => {
// 			for (let k = 1; k <= 4; k++) {
// 				var a1 = document.getElementById(pagePrefix + k);
// 				a1.classList.remove("tran");
// 			}
// 		}, 1400);
// 	}
// }

// function rightSlide() {
// 	if (click) {
// 		if (curpage == 4) curpage = 0;
// 		console.log("woek");
// 		sliding = true;
// 		curpage++;
// 		svg = false;
// 		click = false;
// 		for (let k = 1; k <= 4; k++) {
// 			var a1 = document.getElementById(pagePrefix + k);
// 			a1.className += " tran";
// 		}
// 		setTimeout(() => {
// 			move();
// 		}, 200);
// 		setTimeout(() => {
// 			for (let k = 1; k <= 4; k++) {
// 				var a1 = document.getElementById(pagePrefix + k);
// 				a1.classList.remove("tran");
// 			}
// 		}, 1400);
// 	}
// }

// function move() {
// 	if (sliding) {
// 		sliding = false;
// 		if (svg) {
// 			for( let j = 1; j <= 9; j++) {
// 				var c = document.getElementById(transitionPrefix + j);
// 				c.classList.remove("steap");
// 				c.setAttribute("class", transitionPrefix + j + " streak");
// 				console.log("streak");
// 			}
// 		} else {
// 			for (let j = 10; j <= 18; j++) {
// 				var c = document.getElementById(transitionPrefix + j);
// 				c.classList.remove("steap");
// 				c.setAttribute("class", transitionPrefix + j + " streak");
// 				console.log("streak");
// 			}
// 		}
// 		setTimeout(() => {
// 			for ( let i = 1; i <= 4; i++) {
// 				if (i == curpage) {
// 					var a = document.getElementById(pagePrefix + i);
// 					a.className += " up1";
// 				} else {
// 					var b = document.getElementById(pagePrefix + i);
// 					b.classList.remove("up1");
// 				}
// 			}
// 			sliding = true;
// 		}, 600);
// 		setTimeout(() => {
// 			click = true;
// 		}, 1700);

// 		setTimeout(() => {
// 			if (svg) {
// 				for (let j = 1; j <= 9; j++) {
// 					var c = document.getElementById(transitionPrefix + j);
// 					c.classList.remove("streak");
// 					c.setAttribute("class", transitionPrefix + j + " steap");
// 				}
// 			} else {
// 				for ( let j = 10; j <= 18; j++) {
// 					var c = document.getElementById(transitionPrefix + j);
// 					c.classList.remove("streak");
// 					c.setAttribute("class", transitionPrefix + j + " steap");
// 				}
// 				sliding = true;
// 			}
// 		}, 850);
// 		setTimeout(() => {
// 			click = true;
// 		}, 1700);
// 	}
// }

// left.onmousedown = () => {
// 	leftSlide();
// };

// right.onmousedown = () => {
// 	rightSlide();
// };

// document.onkeydown = (e) => {
// 	if (e.keyCode == 37) {
// 		leftSlide();
// 	} else if (e.keyCode == 39) {
// 		rightSlide();
// 	}
// };

//for codepen header
// setTimeout(() => {
// 	rightSlide();
// }, 500);


}


hundiClk() {
    bootbox.alert('Hundi');
}

gdonateClk() {
    bootbox.alert('Genaral Donation');
};

adonateClk() {
    bootbox.alert('Annadhanam Donation');
};

bookClk() {
    bootbox.alert('Booking');
};

lFlag: boolean = false;

}
